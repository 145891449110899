import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, ModalFooter, Label, Container, Nav, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api, url } from "../../helpers/api";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ToastMessage, DefaultDateWithTime, DefaultDate } from "../../Components/Hooks/Function";
import { getSettingsDetails } from "../../helpers/api_helper";
import DeleteModal from "../../Components/Common/DeleteModal";


// const { htmlToText } = require('html-to-text');

const EmailToolbar = () => {
    const [projectList, setProjectList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value); const [projectMailList, setProjectMail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [activeTabs, setActive] = useState("all");
    const [isTypeTab, setIsTypeTab] = useState("all");
    const [displayCategory, setCategory] = useState("all");
    const [displaytype, settype] = useState("all");
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalAllConfirm, setModalAllConfirm] = useState(false);

    const [messageLoading, setMessageLoading] = useState(false);

    const [resendList, setResendList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);

    const [deleteAllModal, setDeleteAllModal] = useState(false);

    const [emailData, setEmailData] = useState([]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getProjectMailPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getProjectMailPage(currentPage + 1, rowPerPage);
    };

    const toggleTab = (ncategory, ntype) => {

        sidebarClose("email-detail-show");
        
        if (activeTabs !== ncategory) {
            setActive(ncategory);
        }

        if (isTypeTab !== ntype) {
            setIsTypeTab(ntype);
        }
        setCategory(ncategory);
        settype(ntype);
    };

    // SideBar Open
    function sidebarOpen(value, ele) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.add(value);
        ele.closest("li").classList.remove("unread");
    }

    // SideBar Close
    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.remove(value);
    }

    useEffect(() => {
        sidebarClose("email-detail-show");
    }, []);


    const onChangeCheckBox = (value, check) => {

        if (check) {
            setResendList([...resendList, value.toString()]);
        } else {
            setResendList(resendList.filter((selectedItem) => selectedItem !== value.toString()));
        }

    };


    const [emailinfo, setEmailinfo] = useState([]);

    const getMessageProject = async () => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`messageProject`, {
                params: {
                    currentPage: currentPage,
                    rowPerPage: rowPerPage,
                },
            });

            if (response.data.status === true) {

                setProjectMail(response.data?.data);
            } else {
                setProjectMail([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const getProjects = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`getAllProject`, {});
            if (response.data.status === true) {
                setProjectList(response.data?.data);

            } else {
                setProjectList([]);

                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const getProjectMailPage = async (currentPage, rowPerPage) => {
        // setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`messageProject`, {
                params: {
                    currentPage: currentPage,
                    rowPerPage: rowPerPage,
                },
            });
            if (response.data.status === true) {

                setProjectMail(response.data?.data);

            } else {

                setProjectMail([]);
            }
            //  setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };


    const getSettings = async () => {
        setSelectedCountry(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    };

    useEffect(() => {
        getMessageProject();
        getProjects();
        getSettings();
    }, []);


    function convertToPlain(html) {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        var plainText = tempDivElement.textContent || tempDivElement.innerText || "";
        var trimmedText = plainText.replace(/\s+/g, ' ');

        return trimmedText?.length > 50 ? trimmedText?.substring(0, 50) + "...." : trimmedText;
    }



    const handleSend = async (id) => {
        setMessageLoading(true);

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`resend/${id}`, {});
            if (response.data.status === true) {

                setModalConfirm(false);
                getMessageProject();
                setEmailinfo(response.data.data);
                ToastMessage("success", response.data?.message);

            } else {

                ToastMessage("error", response.data?.message);
            }

            setMessageLoading(false);


        } catch (err) {
            setMessageLoading(false);
            console.error(err.message);
        }
    };


    const handleSendAll = async () => {
        setMessageLoading(true);

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`resendAll`, { "resendList": resendList });
            if (response.data.status === true) {

                setModalAllConfirm(false);
                getMessageProject();
                sidebarClose("email-detail-show");
                setResendList([]);
                ToastMessage("success", response.data?.message);

            } else {

                ToastMessage("error", response.data?.message);
            }

            setMessageLoading(false);


        } catch (err) {
            setMessageLoading(false);
            console.error(err.message);
        }
    };




    const handleDelete = async (id) => {
        setMessageLoading(true);

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.delete(`messageProject/${id}`, {});
            if (response.data.status === true) {

                setDeleteModal(false);
                getMessageProject();
                setEmailinfo(response.data.data);
                ToastMessage("success", response.data?.message);

            } else {

                ToastMessage("error", response.data?.message);
            }

            setMessageLoading(false);


        } catch (err) {
            setMessageLoading(false);
            console.error(err.message);
        }
    };


    const handleDeleteAll = async () => {
        setMessageLoading(true);

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`destroyAll`, { "deleteList": resendList });
            if (response.data.status === true) {

                setDeleteAllModal(false);
                getMessageProject();
                sidebarClose("email-detail-show");
                setResendList([]);
                ToastMessage("success", response.data?.message);

            } else {

                ToastMessage("error", response.data?.message);
            }

            setMessageLoading(false);


        } catch (err) {
            setMessageLoading(false);
            console.error(err.message);
        }
    };




    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />

            <DeleteModal
                name={emailData?.id}
                show={deleteModal}
                onDeleteClick={() => handleDelete(emailData.id)}
                onCloseClick={() => setDeleteModal(false)}
            />



            <Modal isOpen={deleteAllModal} toggle={() => setDeleteAllModal(false)} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#FFBC0A,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you sure you want to remove all this record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setDeleteAllModal(false)
                            }}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => handleDeleteAll()}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>


            <div className="email-menu-sidebar">
                <div className="px-4 py-0 d-flex flex-column h-100">
                    {/* <div className="pb-4 border-bottom border-bottom-dashed">
                        <button  type="button"   className="btn btn-danger w-100"  onClick={() => {  setModal(true);   }}  >
                            <FeatherIcon icon="plus-circle" className="icon-xs me-1 icon-dual-light" />
                            Compose
                        </button>
                    </div> */}

                    <SimpleBar className="mx-n4 px-4 email-menu-sidebar-scroll" data-simplebar  >
                        <div className="mail-list mt-2">
                            <Link onClick={() => { toggleTab("all", "all"); }} className={classnames({ active: activeTabs === 'all', 'my-2 border-bottom border-bottom-solid': true })}>
                                <i className="ri-file-list-fill me-3 align-middle fw-medium"></i>{" "}  <span className="mail-list-link">  All {" "}  </span>
                                {/* <span className="badge bg-success-subtle text-success ms-auto">5</span> */}
                            </Link>

                            {projectList?.length !== 0 &&
                                (projectList)?.map((item, index) => (
                                    <Link key={index} onClick={() => { toggleTab(item.id, "all"); }} className={classnames({ active: activeTabs === item.id, 'my-2 border-bottom border-bottom-solid': true })}>
                                        <i className="ri-file-list-fill me-3 align-middle fw-medium"></i>{" "}    <span className="mail-list-link"> {item.name}  </span>
                                        {/* <span className="badge bg-success-subtle text-success ms-auto">5</span> */}
                                    </Link>
                                ))
                            }
                        </div>
                    </SimpleBar>
                </div>
            </div>


            <div className="email-content border border-top-0 border-right-0 border-bottom-0">
                <div className="px-4 py-0 pb-0">
                    <div className="border-bottom border-bottom-dashed">
                        {/* <Row className="mt-n2 mb-3 mb-sm-0">
                            <Col className="col-sm-auto order-1 d-block d-lg-none">
                                <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn">
                                    <i className="ri-menu-2-fill align-bottom"></i>
                                </button>
                            </Col>
                            <div className="col-sm order-3 order-sm-2">
                                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                                    <div className="form-check fs-14 m-0">
                                        <input className="form-check-input" type="checkbox" value="" id="checkall"
                                            onChange={e => {
                                                checkedAll(e.target.value);
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="checkall"></label>
                                    </div>

                                    <div id="email-topbar-actions" style={{ display: "none" }}>
                                        <div className="hstack gap-sm-1 align-items-center flex-wrap">
                                            <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip1">
                                                <i className="ri-inbox-archive-fill align-bottom"></i>
                                            </button>
                                            <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip2">
                                                <i className="ri-error-warning-fill align-bottom"></i>
                                            </button>
                                            <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip3" onClick={() => { setDeleteModal(true); }} >

                                                <i className="ri-delete-bin-5-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="vr align-self-center mx-2"></div>

                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-price-tag-3-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Support</DropdownItem>
                                            <DropdownItem>Freelance</DropdownItem>
                                            <DropdownItem>Social</DropdownItem>
                                            <DropdownItem>Friends</DropdownItem>
                                            <DropdownItem>Family</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-more-2-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem onClick={() => readAll()}>Mark all as Read</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <Alert color="warning" style={{ display: "none" }} className="unreadConversations-alert px-4 fade" id="unreadConversations">
                                        No Unread Conversations
                                    </Alert>

                                    <UncontrolledTooltip placement="top" target="Tooltip1"> Archive </UncontrolledTooltip>
                                    <UncontrolledTooltip placement="top" target="Tooltip2"> Report Spam </UncontrolledTooltip>
                                    <UncontrolledTooltip placement="top" target="Tooltip3"> Trash </UncontrolledTooltip>
                                </div>
                            </div>
                            <div className="col-auto order-2 order-sm-3">
                                <div className="d-flex gap-sm-1 email-topbar-link">
                                    <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                        <i className="ri-refresh-line align-bottom"></i>
                                    </button>
                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-more-2-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Mark as Unread</DropdownItem>
                                            <DropdownItem>Mark as Important</DropdownItem>
                                            <DropdownItem>Add to Tasks</DropdownItem>
                                            <DropdownItem>Add Star</DropdownItem>
                                            <DropdownItem>Mute</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </Row> */}

                        {/* <Row className="mt-n2 mb-3 mb-sm-0">
                            <Col className="col-sm-auto order-1 d-block d-lg-none">
                                <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn">
                                    <i className="ri-menu-2-fill align-bottom"></i>
                                </button>
                            </Col>
                        </Row> */}

                        <Row className="row align-items-end mt-2">
                            <Col xs={6}>
                                <div id="mail-filter-navlist">
                                    <Nav className="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0" role="tablist"    >
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: isTypeTab === "all" }, "fw-semibold")}
                                                onClick={() => { toggleTab(displayCategory, "all"); }}
                                                href="#" >
                                                <i className="ri-inbox-fill align-bottom d-inline-block"></i>
                                                <span className="ms-1 d-none d-sm-inline-block">All</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: isTypeTab === 1 }, "fw-semibold")}
                                                onClick={() => { toggleTab(displayCategory, 1); }}
                                                href="#" >
                                                <i className="ri-mail-check-fill align-bottom d-inline-block"></i>
                                                <span className="ms-1 d-none d-sm-inline-block">Sent</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    { active: isTypeTab === 0 },
                                                    "fw-semibold"
                                                )}
                                                onClick={() => { toggleTab(displayCategory, 0); }}
                                                href="#"  >
                                                <i className="ri-mail-unread-fill align-bottom d-inline-block"></i>
                                                <span className="ms-1 d-none d-sm-inline-block">Pending</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end mt-2">

                                <div className="d-flex align-items-center justify-content-end">
                                    <Input className="form-check-input me-2 mt-0"
                                        type="checkbox" id="check"
                                        checked={(projectMailList?.data)?.length === resendList?.length}
                                        onChange={() => {
                                            if ((projectMailList?.data)?.length === resendList?.length) {
                                                setResendList([]);
                                            } else {
                                                setResendList((projectMailList?.data)?.map((item) => item.id.toString()));
                                            }
                                        }}

                                    /> Select All
                                </div>

                                {resendList?.length > 0 && <Button className='btn btn-primary my-1 py-1' onClick={() => { setModalAllConfirm(true); }} > Send </Button>}


                                {resendList?.length > 0 && <>
                                    <button type="button" className="btn btn-sm btn-icon"
                                        onClick={(e) => { setDeleteAllModal(true); setEmailData(); }}  >
                                        < i className="ri-delete-bin-fill fs-18 secondary" ></i>
                                    </button> </>}

                                <div className="text-muted"> {(projectMailList?.from || 1) + " - " + (projectMailList?.to || 1) + " of " + (projectMailList?.total || 1)} </div>
                                <div>
                                    <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={currentPage === 1}>
                                        <MdOutlineKeyboardArrowLeft size={25} />
                                    </button>
                                    <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={(currentPage * rowPerPage >= projectMailList?.total ? projectMailList?.total : rowPerPage) === projectMailList?.total}>
                                        <MdOutlineKeyboardArrowRight size={25} />
                                    </button>
                                </div>
                            </div>
                        </Row>
                    </div>

                    <div className="message-list-content mx-n4 px-4 message-list-scroll">
                        {
                            // isLoader ? <div id="elmLoader">
                            //     <div className="spinner-border text-primary avatar-sm" role="status">
                            //         <span className="visually-hidden">Loading...</span>
                            //     </div>
                            // </div>
                            //     :
                            <ul className="message-list" id="mail-list">
                                {
                                    (projectMailList?.data)?.filter(item => ((displaytype === "all" ? true : item.status === displaytype)
                                        && (displayCategory === "all" ? true : item.project_id === displayCategory)))?.map((item, key) => {

                                            return (
                                                <li className="border-0 border-bottom" key={key} >
                                                    <div className="col-mail col-mail-1">
                                                        <div className="d-none d-md-flex form-check checkbox-wrapper-mail fs-14">
                                                            <input onChange={(e) => { onChangeCheckBox(item.id, e.target.checked); }}
                                                                className="form-check-input" type="checkbox"
                                                                checked={(resendList)?.filter((list) => list === item.id.toString())?.length == 1}
                                                                id={item.id} />
                                                            {/* <label className="form-check-label" htmlFor={item.id}></label> */}



                                                            <button type="button" className="btn btn-sm btn-icon" style={{ margin: "-4px 0 0 2px" }}
                                                                onClick={(e) => { setDeleteModal(true); setEmailData(item); }}  >
                                                                < i className="ri-delete-bin-fill fs-18 secondary" ></i>

                                                            </button>

                                                        </div>

                                                        <Link className="title" onClick={(e) => { sidebarOpen("email-detail-show", e.target); setEmailinfo(item); }}>{item.project?.name}   </Link>
                                                    </div>
                                                    <div className="col-mail col-mail-2" onClick={(e) => { sidebarOpen("email-detail-show", e.target); setEmailinfo(item); }}>
                                                        <Link className="subject">  <span className={item.status ? "badge me-2 bg-success" : "badge me-2 bg-danger"}>{item.status ? "Sent" : "Pending"}</span>


                                                            {/* <span className='d-none d-md-inline'> {item.subject}  - <span className="teaser ">  {convertToPlain(item?.message)}  </span> </span> */}


                                                            <span className='d-none d-md-inline'> {"Type : " + item.type + ", "}  </span>

                                                            <span className='d-none d-md-inline'> {"To : " + item.to}
                                                                {/* {`${item.cc != null ? " - CC : " + item.cc : ""}`}  */}

                                                            </span>
                                                        </Link>
                                                        <div className="date">{DefaultDateWithTime(item.created_at, selectedCountry)}</div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                            </ul>}
                    </div>
                </div>
            </div >

            <div className="email-detail-content">
                <div className="p-4 d-flex flex-column h-100">
                    <div className="pb-2 border-bottom border-bottom-dashed">
                        <Row>
                            <Col className="col">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="fw-bold email-subject-title mb-0">{emailinfo?.subject}</h5>
                                    <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => sidebarClose("email-detail-show")}>
                                        <i className="ri-close-fill align-bottom"></i>
                                    </button>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                        <div className="mt-4 mb-3">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Name </Label>  <br />  {emailinfo?.project?.name}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Type </Label>   <br />  {emailinfo?.type}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> From</Label>   <br />    {emailinfo?.from}

                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> To </Label> <br /> {emailinfo?.to}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> CC </Label> <br /> {emailinfo?.cc}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Subject </Label> <br /> {emailinfo?.subject}
                                    </div>
                                </Col>


                                {/* <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> From Name </Label>   <br />   {emailinfo?.project?.mail_from_name}
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> From Address  </Label>   <br /> {emailinfo?.project?.mail_from_address}
                                    </div>
                                </Col> */}



                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Status  </Label>   <br /> {emailinfo?.status ? "Sent" : "Pending"}
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Status Date  </Label>   <br /> {DefaultDateWithTime(emailinfo?.status_date ? emailinfo?.status_date : emailinfo?.created_at, selectedCountry)}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Created At  </Label>   <br /> {DefaultDateWithTime(emailinfo?.created_at, selectedCountry)}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Send Manually </Label>   <br />   <Button className='btn btn-primary' onClick={() => { setModalConfirm(true); }} > Send </Button>
                                    </div>
                                </Col>


                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Resend Count </Label>   <br />    {emailinfo?.resent? emailinfo?.resent : 0 }
                                    </div>
                                </Col>


                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Resend Date </Label>   <br />     {emailinfo?.resent ? DefaultDateWithTime(emailinfo?.resent_date, selectedCountry) : "N/A"}
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label className="form-label fw-bold"> Message </Label> <br />     <div dangerouslySetInnerHTML={{ __html: emailinfo?.message }}></div>
                                    </div>
                                </Col>
                            </Row>

                            <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                                <div className="modal-dialog modal-dialog-centered"    >
                                    <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                                        setModalConfirm(!modalConfirm)
                                    }} centered>

                                        <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                                            setModalConfirm(!modalConfirm)
                                        }} >
                                            {"Confirm"}

                                        </ModalHeader>

                                        <ModalBody >
                                            <p> Are you sure you want to send? </p>
                                        </ModalBody >

                                        <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">


                                                <button type="submit" className="btn btn-primary shadow-lg"
                                                    disabled={messageLoading}
                                                    onClick={() => {
                                                        handleSend(emailinfo?.id);
                                                    }}>
                                                    {messageLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                    Yes</button>

                                            </div>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>



                            <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                                <div className="modal-dialog modal-dialog-centered"    >
                                    <Modal size="md" backdrop={"static"} isOpen={modalAllConfirm} toggle={() => {
                                        setModalAllConfirm(!modalAllConfirm)
                                    }} centered>

                                        <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                                            setModalAllConfirm(!modalAllConfirm)
                                        }} >
                                            {"Confirm"}

                                        </ModalHeader>

                                        <ModalBody >
                                            <p> Are you sure you want to send to all? </p>
                                        </ModalBody >

                                        <ModalFooter>
                                            <div className="hstack gap-2 justify-content-end">


                                                <button type="submit" className="btn btn-primary shadow-lg"
                                                    disabled={messageLoading}
                                                    onClick={() => {
                                                        handleSendAll();
                                                    }}>
                                                    {messageLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                    Yes</button>

                                            </div>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>

                        </div>
                    </SimpleBar>

                </div>
            </div>

        </React.Fragment >
    );
};

export default EmailToolbar;