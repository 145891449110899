module.exports = { 
  urlList: { 
      // API_URL: window.location.origin  === 'http://localhost:3000'? "http://127.0.0.1:8000/api/" : "http://apidevfairmail.fairreturns.in/api/",      
      // SITE_URL: window.location.origin  === 'http://localhost:3000'? "http://127.0.0.1:8000/" : "http://apidevfairmail.fairreturns.in/", 
      
       API_URL: "http://apidevfairmail.fairreturns.in/api/",      
       SITE_URL: "http://apidevfairmail.fairreturns.in/", 
    
  }, 
}; 

 