import axios from "axios";
import { urlList } from "../config";

 
let url = urlList.SITE_URL; 

const api = axios.create({
  baseURL: urlList.API_URL,
}); 

export { api, url};